<template>
  <div>
    <!-- /AWeber Web Form Generator 3.0.1 -->
    <form
      class="flex flex-col gap-2"
      method="post"
      accept-charset="UTF-8"
      action="https://www.aweber.com/scripts/addlead.pl"
    >
      <div style="display: none">
        <input type="hidden" name="meta_web_form_id" value="1768233986" />
        <input type="hidden" name="meta_split_id" value="" />
        <input type="hidden" name="listname" value="awlist4172283" />
        <input
          type="hidden"
          name="redirect"
          value="https://www.aweber.com/thankyou-coi.htm?m=text"
          id="redirect_cbaa71fe2f2e82da70f7094e38963409"
        />

        <input type="hidden" name="meta_adtracking" value="My_Web_Form_2" />
        <input type="hidden" name="meta_message" value="1" />
        <input type="hidden" name="meta_required" value="name,email" />

        <input type="hidden" name="meta_tooltip" value="" />
      </div>

      <input
        class="appearance-none w-full px-4 py-2 mb-3 border border-gray-300 text-base rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 lg:max-w-xs"
        placeholder="Enter your name"
        name="name"
        type="text"
        required
        autocomplete="name"
      />

      <input
        class="appearance-none w-full px-4 py-2 border border-gray-300 text-base rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 lg:max-w-xs"
        placeholder="Enter your email"
        name="email"
        type="email"
        required
        autocomplete="email"
      />

      <div
        class="mt-2 flex-shrink-0 w-full flex rounded-md shadow-sm sm:inline-flex"
      >
        <BaseButton
          type="submit"
          class="w-full bg-indigo-600 px-4 py-2 border border-transparent rounded-md flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:inline-flex"
        >
          Notify me
        </BaseButton>
      </div>
      <div class="mt-6 space-y-4">
        <img src="@/assets/logo.jpg" alt="Arete Publishing logo" />
        <p>
          We hate spam and agree never to pass on your details. This is for your
          subscription purposes only
        </p>
      </div>
    </form>
  </div>
</template>

<script setup></script>

<style module></style>
